import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import LoginView from '../views/LoginView.vue';
import PdfView from '../views/PdfView.vue';
import cookies from "vue-cookies";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
   
  },

  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { norequiresAuth: true },
  },
  {
    path: '/home',
    component: HomeView,
  },
  {
    path: '/pdf',
    component: PdfView,
  },
];

const router = new VueRouter({
  routes,
});

// 添加一个导航守卫以检查路由是否需要身份验证
router.beforeEach((to, from, next) => {
  if (!to.matched.some((record) => record.meta.norequiresAuth)) {
    // 此路由需要身份验证
    //获取cookie
    var token = cookies.get("token")
    if (!token) {
      next({
        name: 'login',
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
