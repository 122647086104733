<template>
   <div>
    <div class="title">{{title}}</div>
    <pdf :title="type"></pdf>
   </div>
  </template>
  
  <script>
  import pdf from '../components/pdfView.vue'
  
  export default {
    components: {
      pdf
    },
    data () {
      return {
        title:'',
        type:null
      }
    },
    created () {
      let query = this.$route.query
      if(query.name){
        this.title = query.name
        this.type = query.type
      }
    },
    destroyed () {
    },
    mounted () {
    
    },

    methods: {
      
     
    }
  }
  </script>
  
  <style scoped>
  .title{
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    height: 60px;
    line-height: 60px;
    background: #333;
    color: #fff;
    margin-bottom: 20px;
  }
 .pdf{
    width: 100%;
    height: 100%;
  }
 .pdf-container{
    width: 100%;
    height: 100%;
  
  }
  </style>
  
  