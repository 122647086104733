<template>
    <div class="login">
        <div class="login-title">新诤信数字资产管理平台</div>
        <div class="login-container">

            <el-form ref="loginForm" :model="loginForm" label-width="80px" :rules="rules" class="login-form">
                <el-form-item label="账号" prop="username">
                    <el-input v-model="loginForm.username" placeholder="请输入账号"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input type="password" v-model="loginForm.password" placeholder="请输入密码"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button style="width: 100%;" type="primary" @click="login">登录</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>

</template>

<script>
    import cookies from "vue-cookies";
    export default {

        data() {
            return {
                loginForm: {
                    username: '',
                    password: '',
                },
                rules: {
                    username: [{
                        required: true,
                        message: '请输入账号',
                        trigger: 'blur'
                    }, ],
                    password: [{
                            required: true,
                            message: '请输入密码',
                            trigger: 'blur'
                        },
                        {
                            min: 6,
                            max: 16,
                            message: '长度在 6 到 16 个字符',
                            trigger: 'blur'
                        }
                    ]
                }

            };
        },
        methods: {
            generateRandomToken(length) {
                const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
                let token = "";
                for (let i = 0; i < length; i++) {
                    const randomIndex = Math.floor(Math.random() * characters.length);
                    token += characters.charAt(randomIndex);
                }
                return token;
            },

            //登录的账号密码匹配AccountData内是否包含该账号密码
            checkAccountPassword() {
                const AccountData = JSON.parse(window.sessionStorage.getItem('AccountData'));
                // AccountData数组是否包含该账号密码 如果有找到该项目 存入window.sessionStorage config KEY 中
                let config = AccountData.find(item => item.username === this.loginForm.username && item.password === this.loginForm.password)
                if(config){
                    window.sessionStorage.setItem('config',JSON.stringify(config));
                    return true
                }else{
                    return false
                }
            },

            login() {
                this.$refs.loginForm.validate(valid => {
                    console.log(valid);
                    if (!valid) return this.$message.warning('请输入账号密码')
                    if (this.checkAccountPassword()) {
                        const token = this.generateRandomToken(64);
                        cookies.set('token', token, {
                            maxAge: 60 * 60 * 2,
                        });
                        this.$router.push('/home');
                    } else {
                        this.$message.warning('账号或密码错误');
                    }


                });

            },
        },
    };
</script>

<style scoped>
    .login {
        height: 100vh;
        background-image: url('../assets/login.png') ;
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .login-container {
        display: flex;
        align-items: center;
        justify-content: center;
       

    }

    .login-form {
        max-width: 300px;
        width: 100%;
        padding: 36px 36px 24px;
        border-radius: 8px;
        background: rgba(173, 216, 230,.7);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .login-title {
        padding-top:164px ;
        font-size: 32px;
        color: #fff;
        margin-bottom: 32px;
        text-align: center;
        font-weight: bold;

    }
</style>