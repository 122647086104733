<template>
  <div class="home">
    <div class="hearder">
      <div class="logoName">新诤信数字资产管理平台</div>
      <div class="name">{{configData.name||''}} | 
      <span style="font-size: 14px;">{{configData.username||''}}，欢迎您  &ensp;&ensp;  </span> 
      <span class="hover" :style="{
        fontSize:'14px'
      }" @click="outLogin">退出</span></div>
    </div>
    <div class="title">{{configData.name||''}}资产清单</div>
    <div class="itemList">
      <template v-for="item in items">
        <el-card :class="`item `" :key="item.id" @click.native="itemClick(item)">
          <div :class=" `itemimg item${item.class}`"></div>
          <div class="itemname" >{{item.name}}</div>
        </el-card>
      </template>


    </div>
  </div>
</template>

<script>
  import cookies from "vue-cookies";

  export default {
    data() {
      return {
        items: [],
        hashMap: [{
            type: 1,
            class:1,
            name: '低碳管理平台',
            isThird: 1,
            url: 'https://dtsjzc.jinfull.com/carbon/#/carbon'
          },
          {
            type: 2,
            class:2,
            name: '全球数字招商平台',
            isThird: 1,
            url: 'https://dipsjzc.jinfull.com'
          },
          {
            type: 3,
            class:3,
            name: '产业数据可视化系统',
            isThird: 1,
            url: 'https://inbsjzc.jinfull.com/#/login'
          },
          {
            type: 5,
            class:12,
            name: '智慧口岸项目-智慧加贸服务平台',
            isThird: 1,
            url: 'https://tb.my118.com/customs/cx'
          },
        
          {
            type: 6,
            class:6,
            name: '产业互联网-Web3.0智能配置交易平台',
            isThird: 1,
            url: 'https://znpzsjzc.jinfull.com'
          },
          {
            type: 7,
            class:7,

            name: '产业主数据标准化CPMS管理平台',
            isThird: 1,
            url: 'https://cpmssjzc.jinfull.com/'
          },
          {
            type: 8,
            class:12,

            name: '智慧口岸项目-物流金融协同服务平台-海外检验',
            isThird: 1,
            url: 'https://tb.my118.com/abroadinspect/'
          },{
            type: 9,
            class:12,

            name: '智慧口岸项目-物流金融协同服务平台-海外供应商',
            isThird: 1,
            url: 'https://tb.my118.com/abroadsupply/'
          },{
            type: 10,
            class:12,

            name: '智慧口岸项目-物流金融协同服务平台-金融端',
            isThird: 1,
            url: 'https://tb.my118.com/finance/'
          },{
            type: 11, 
            class:12,

            name: '智慧口岸项目-物流金融协同服务平台-贸易服务商',
            isThird: 1,
            url: 'https://tb.my118.com/financetrade/'
          },
        ],
        configData: {}
      };
    },
    mounted() {
      const config = JSON.parse(window.sessionStorage.getItem('config'))
      //根据config的menu 匹配hashMap
      this.items = this.hashMap.filter(item => config.menu.includes(item.type))
      this.configData = config

    },
    methods: {
      itemClick(item) {
        console.log(item);
        // let url
        if (item.isThird) {
          window.open(item.url)
        } else {
          this.$router.push({
            path: '/pdf',
            query: {
              name: item.name,
              type: item.type
            }
          })
        }


        // window.open(url)
      },
      outLogin() {
        cookies.remove('token')
        this.$router.push('login')
      }
    },
  };
</script>


<style scoped>
  .home {
    position: relative;
    padding-bottom:48px ;
    min-height: calc(100vh);

    background:url('../assets/11.png') no-repeat ;
  }

  .hearder {
    position: relative;
    height: 70px;
    background: #00152970;
  }

  .logoName {
    position: absolute;
    top: 24px;
    left: 32px;
    font-size: 24px;
    color: #fff;
    padding-left: 16px;
  }

  .name {
    position: absolute;
    top: 24px;
    right: 24px;
    font-size: 18px;
    color: #fff;
    padding-left: 16px;
  }

  .title {
    position: absolute;
    top: 200px;
    left: 15vw;
    font-size: 32px;
    color:#fff ;
    padding: 24px 0 0;

  }

  .itemList {
    padding: 0 15vw;
    padding-top:400px ;
    display: flex;
    flex-wrap: wrap;
    gap: 36px;

  }

  .item {
    width: 250px;
    cursor: pointer;
  }

  /* 鼠标放上去变大1.1倍动画 */
  .item:hover {
    transform: scale(1.1);
    transition: all 0.5s;
  }

  .hover {
    cursor: pointer;
  }
 
  .item1{
    background: url('../assets/1.png') 

  }
  .item2{
    background: url('../assets/2.png') 

    
  }
 .item3{
    background: url('../assets/3.png')

  }
 .item6{
    background: url('../assets/6.png') 

  }
  .item7{
    background: url('../assets/7.png') 

  }
  .item12{
    background: url('../assets/12.png')  

  }
  .itemimg{
    width: 100%;
    height: 160px;
    background-size: 100% 100%;
    background-repeat: no-repeat; 
  }
  .itemname{
    font-size: 16px;
    color: #000;
    padding: 16px 0;
    font-weight: 600;
  
  
  }
  ::v-deep .el-card__body{
    padding: 0;
   }
</style>